// import helper functions
import {
  ConstructScene,
  Lights
} from '@/assets/js/helper.js'

import {
  sceneBlack
} from '@/assets/js/library.js'

/**
 * THREE.js imports
 */
import {
  Scene,
  Fog,
  Sphere,
  Object3D,
  ObjectLoader,
  Box3,
  Vector3,
  PerspectiveCamera
} from 'three'

const loader = new ObjectLoader()

export function onWindowResize (camera, renderer, height, cameraAdjust) {
  // test screen width
  if (cameraAdjust) {
    camera.aspect = (window.innerWidth / height)
    camera.updateProjectionMatrix()
  }

  renderer.setSize(window.innerWidth, height)
}

export function getContextModel (object) {
  return new Promise((resolve, reject) => {
    loader.parse(object,
      (model) => {
        model.name = 'context-model'

        resolve(model)
      })
  })
}

export function loadModel (object, ID) {
  return new Promise((resolve, reject) => {
    loader.parse(object,
      (model) => {
        model.name = 'model-' + ID

        resolve(model)
      },
      (xhr) => {
        console.warn((xhr.loaded / xhr.total) * 100)
      },
      (err) => {
        console.error('failed to load model', err)
        reject(err)
      }
    )
  })
}

export function setupModelScene (model, ID, contextModel) {
  const bb = new Box3()

  bb.expandByObject(model)

  bb.expandByObject(contextModel)

  const center = new Vector3()

  bb.getCenter(center)

  // TODO:
  // contact Snoweria regarding model position
  let modelSettings = {
    x: -center.x,
    y: center.y,
    z: -center.z
  }

  contextModel.position.set(
    modelSettings.x,
    modelSettings.y,
    modelSettings.z
  )

  model.position.set(
    modelSettings.x,
    modelSettings.y,
    modelSettings.z
  )

  model.name = 'model-' + ID

  const ms = bb.getBoundingSphere(new Sphere())

  let cameraRadius = ms.radius / 2 * (1 + Math.sqrt(5))

  return [model, contextModel, cameraRadius, modelSettings]
}

export function createCopy (object) {
  const copyObject = new Object3D()
  copyObject.copy(object)

  return copyObject
}

/**
 *
 * @param { Array } scenes array of scenes
 * @param { Object } renderer THREE.js renderer object
 */
export function renderScenes (scenes, renderer) {
  renderer.setClearColor(sceneBlack)
  renderer.setScissorTest(false)
  renderer.clear()
  // renderer.setClearColor(0xe0e0e0)
  renderer.setScissorTest(true)

  scenes.forEach((scene) => {
    const element = scene.userData.element

    // get its position relative to the page's viewport
    const rect = element.getBoundingClientRect()

    const bottomOffset = 75

    // set the frame
    const width = rect.right - rect.left
    const height = rect.height - (bottomOffset - 58)
    const left = rect.left
    const bottom = renderer.domElement.clientHeight - rect.bottom + bottomOffset

    renderer.setViewport(left, bottom, width, height)
    renderer.setScissor(left, bottom, width, height)

    // camera.aspect = width / height; // not changing in this example
    // camera.updateProjectionMatrix();
    // scene.userData.controls.update() // may need if switching to animation frame
    renderer.render(scene, scene.userData.camera)
  })
}

/**
 *
 * @param { Array } models
 * @param { Array} scenes
 * @param { Object3D } contextOBJ
 */
export function createScenes (models, scenes, contextOBJ) {
  models.forEach((model) => {
    let contextModel = createCopy(contextOBJ)
    // create the scene
    const createScene = new ConstructScene(
      Scene,
      sceneBlack,
      new Fog(0x1a2050, 10000, 10000)
    )

    createScene.constructCamera(PerspectiveCamera, 0)

    let scene = createScene.scene
    const modelID = model['metadata']['ID'].split('_option.json')[0]

    scene.name = modelID

    scene.userData.camera = createScene.camera
    scene.userData.camera.zoom = 1.5
    scene.userData.camera.updateProjectionMatrix()

    // setup lights
    const lights = new Lights()
    lights.createDirectionalLight()

    scene.add(lights.ambient)
    scene.add(lights.directionalLight)
    scene.add(lights.directionalLight2)

    loader.parse(model, obj => {
      obj.name = 'scene-object-' + modelID

      const bb = new Box3()

      bb.expandByObject(obj)

      const center = bb.getCenter(new Vector3())

      const modelSettings = {
        x: -center.x,
        y: -center.y,
        z: -center.z
      }

      obj.position.set(
        modelSettings.x,
        modelSettings.y * 2,
        modelSettings.z
      )

      contextModel.position.set(
        modelSettings.x,
        modelSettings.y * 2,
        modelSettings.z
      )

      scene.userData.modelSettings = modelSettings
      scene.userData.createScene = createScene

      const ms = bb.getBoundingSphere(new Sphere())

      const cameraRadius = (ms.radius / 2) * (1 + Math.sqrt(5))

      scene.userData.camera.position.set(
        cameraRadius,
        cameraRadius,
        -cameraRadius
      )

      scene.add(obj)
      scene.add(contextModel)
    })

    scenes.push(scene)
  })

  return scenes
}
